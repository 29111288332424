import React from "react";
import "./Button.scss";

const Button = ({ type, className, style, action = () => null, link, upperCase = true, disabled, children }) => {
  const typeClasses = type ? " " + type : "";
  const propClasses = className ? " " + className : "";
  const classes = `button${typeClasses}${propClasses}${disabled ? " disabled" : ""}`;

  let content = children;
  if (typeof children === "string" && upperCase) {
    content = children.toUpperCase();
  }

  const button = (
    <button className={classes} style={style} onClick={action} disabled={disabled}>
      {content}
    </button>
  );
  return link ? <a href={link}>{button}</a> : button;
};

export default Button;
