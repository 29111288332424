import React from "react";
import ReactDOM from "react-dom";
import { configure as configureMobX } from "mobx";
import { AmplifyService } from "src/services";
import App from "src/app";
import "src/styles/index.scss";

configureMobX({ enforceActions: "never" });
AmplifyService.initialize();

ReactDOM.render(<App />, document.getElementById("root"));
