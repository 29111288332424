import React, { useState } from "react";
import { observer } from "mobx-react";
// import { decodeParamSignature } from "../../../utils";
// import useQueryParams from "frontend-shared/hooks/useQueryParams";
import useOnEnterPressed from "frontend-shared/hooks/useOnEnterPressed";
import AuthStore from "src/stores/AuthStore";
import Input from "frontend-shared/components/Input";
import "./VerifyAccount.scss";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";

function VerifyAccount() {
  // const { p: authParams } = useQueryParams();

  const [code, setCode] = useState("");
  const updateCode = ({ target }) => setCode(target.value);

  const navigate = useNavigate();

  const [error, setError] = useState();

  const verifyEnabled = code && code.length === 6;

  const submit = async () => {
    if (verifyEnabled) {
      setError(null);
      try {
        const { username, password } = AuthStore.tempSignupData;
        const { success, error } = await AuthStore.verifyAccount(username, password, code);
        navigate("/auth");
        if (!success && error) setError(error);
      } catch (err) {
        console.warn(err);
      }
    }
  };
  useOnEnterPressed(submit);

  const resendCode = async () => {
    const { email } = AuthStore.tempSignupData;
    await AuthStore.resendVerificationCode(email);
  };

  const buttonContents = AuthStore.loading ? "SPINKER" : "VERIFY";
  const buttonStyles = verifyEnabled
    ? AuthStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };

  return (
    <div className="verify-account">
      <div className="auth-content">
        <div className="message">Nice! We sent a verification code to your email - enter it here and we'll get you going.</div>
        <div className="auth-square">
          <div className="auth-inputs">
            <Input placeholder="Verification Code" value={code} onChange={updateCode} error={error} />
          </div>
          <button style={buttonStyles} className="auth-button" onClick={submit}>
            {buttonContents}
          </button>
          <div className="redirect" onClick={resendCode}>
            <span>Didn't get a code?</span>
            <span className="auth-link">Send Another</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(VerifyAccount);
