import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Select } from "frontend-shared/components";
import { STRIPE_POST_CONFIRM_URL } from "src/config";
import ClientDataStore from "src/stores/ClientDataStore";
import "./Pay.scss";
import { add, format } from "date-fns";
import { planPrices, request } from "frontend-shared/utils";
import { AuthStore } from "src/stores";

const Pay = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [plan, setPlan] = useState("Farrier Plus Basic License");
  const updatePlan = e => {
    setPlan(e);
  };
  const [coupon, setCoupon] = useState("BUTLER");
  const updateCoupon = e => {
    setCoupon(e.target.value);
    setCouponValid(null);
  };
  const [couponValid, setCouponValid] = useState(null);

  const [showFauxLoader, setShowFauxLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setShowFauxLoader(false), 800 + Math.random() * 800);
  }, []);

  const [error, setError] = useState("");

  const { stripeLoading, setupIntentSecret } = ClientDataStore || {};

  useEffect(() => {
    if (!setupIntentSecret) navigate("/");
  }, [setupIntentSecret, navigate]);

  const applyCoupon = async () => {
    const res = await request.post("/v1/stripe/coupon", { body: { coupon } });
    setCouponValid(res);
  };

  const submitPayment = async () => {
    if (!stripe || !elements) return;
    //before this, send off subscription create request for client?
    const params = new URLSearchParams();
    params.append("plan", plan.split(" ")[2].toUpperCase());
    if (coupon) params.append("coupon", coupon);
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: `${STRIPE_POST_CONFIRM_URL}?${params.toString()}` }
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message);
      console.warn(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const loading = stripeLoading || !stripe || !elements || showFauxLoader;
  const fauxLoader = loading ? <div className="loading-overlay">LOADING</div> : null;
  const dollars = (planPrices[plan?.split(" ")[2]?.toUpperCase() || "BASIC"] / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
  const stripeErrorMessage = error ? <p className="stripe-error">{error}</p> : null;

  const firstCharge = format(add(new Date(), { days: couponValid ? 90 : 30 }), "MMM do, yyyy");

  return (
    <div className="checkout-root-container">
      <div className="logo-container">FARRIER PLUS</div>
      <div className="content">
        <div className="payment-info-col">
          <div className="title">Billing Info</div>
          <div className="payment-container">
            <div className="paymentbox">
              <PaymentElement />
              <Button className="button" style={{ color: "white" }} action={submitPayment}>
                START TRIAL
              </Button>
              {stripeErrorMessage}
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="explanation-col">
          <div className="explanation-title">You're purchasing</div>
          <div className="explanation-product">
            <Select
              options={["Farrier Plus Basic License", "Farrier Plus Standard License", "Farrier Plus Premium License"]}
              value={plan}
              onChange={updatePlan}
            />
          </div>
          <div className="explanation-price">with a {couponValid ? "90" : "30"} day free trial</div>
          <div className="explanation-price">
            Then <span className="price-label">{dollars}</span>/month,
          </div>
          <div className="explanation-coupon">
            After your trial, you will be charged <span className="price-label">{dollars}</span> a month, starting on {firstCharge}.
          </div>
          <div>
            <input placeholder="COUPON" value={coupon} onChange={updateCoupon} />
            <button onClick={applyCoupon}>Apply</button>
          </div>
          <div>
            <div>Purchasing for {AuthStore?.user?.attributes?.email}</div>
            <button onClick={() => AuthStore?.signOut()}>Not you? Sign out.</button>
          </div>
        </div>
        {fauxLoader}
      </div>
    </div>
  );
};

const ProvidersWrappedPay = () => {
  const { stripePromise, setupIntentSecret } = ClientDataStore || {};
  const options = { clientSecret: setupIntentSecret };

  return (
    <Elements stripe={stripePromise} options={options}>
      <Pay />
    </Elements>
  );
};

export default observer(ProvidersWrappedPay);
