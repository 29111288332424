export const API_NAME = process.env.REACT_APP_API_NAME;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE
};

export const STRIPE_POST_CONFIRM_URL = window.location.origin + "/thankyou";
export const STRIPE_PUBLISHABLE_KEY =
  "pk_test_51LLC3wEGjWuZ23pUZSuieKyx52tacUnlbIEHkzWoUn5oGJPwnquxvWZahMGsT6B7up4XEjK7H3R9zBgM7HCN2E8c00SJYmAUMo";

export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL || "https://espn.com";
