import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import useOnEnterPressed from "frontend-shared/hooks/useOnEnterPressed";
import AuthStore from "src/stores/AuthStore";
import Input from "frontend-shared/components/Input";
import "./ResetPassword.scss";

function ResetPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [sendingCode, setSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const [code, setCode] = useState("");
  const updateCode = ({ target }) => setCode(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [confirmPassword, setConfirmPassword] = useState("");
  const updateConfirmPassword = ({ target }) => setConfirmPassword(target.value);

  const resetEnabled = code && code.length === 6 && password && password.length > 5 && password === confirmPassword;

  const sendCodeEnabled = !!email;
  const sendCode = async () => {
    if (sendCodeEnabled) {
      setSendingCode(true);
      const success = await AuthStore.sendResetPasswordCode(email);
      if (success) {
        setCodeSent(true);
      } else {
        console.warn("Error sending reset password code.");
      }
      setSendingCode(false);
    }
  };

  const resetPassword = async () => {
    if (resetEnabled) {
      const resetSuccess = await AuthStore.resetPassword(email, code, password);
      if (resetSuccess) {
        navigate("/resetpassword/success");
        setTimeout(async () => await AuthStore.signIn(email, password), 3000);
      } else {
        console.warn("Error resetting password.");
      }
    }
  };

  const onEnterPressed = async () => {
    if (resetEnabled) return await resetPassword();
    else if (sendCodeEnabled) return await sendCode();
  };
  useOnEnterPressed(onEnterPressed);

  const codeButtonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};
  const codeButtonContents = sendingCode && AuthStore.loading ? "SPINKER" : `${codeSent ? "RE" : ""}SEND RESET CODE`;

  const resetButtonStyles = !sendingCode && AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};
  const resetButtonContents = !sendingCode && AuthStore.loading ? "SPINKER" : "RESET PASSWORD";

  return (
    <div className="reset-password">
      <div className="auth-square-title">Reset Password</div>
      <div className="inputs-container">
        <div className="auth-inputs">
          <Input
            className="auth-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={updateEmail}
            disabled={codeSent}
          />
          <button
            className={`sign-up-button${sendCodeEnabled ? "" : " disabled"}`}
            style={codeButtonStyles}
            onClick={sendCode}
          >
            {codeButtonContents}
          </button>
          <Input
            className="auth-input"
            type="password"
            placeholder="New Password"
            value={password}
            onChange={updatePassword}
          />
          <Input
            className="auth-input"
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={updateConfirmPassword}
          />
          <Input className="auth-input" placeholder="Code" value={code} onChange={updateCode} />
          <button
            className={`sign-up-button${resetEnabled ? "" : " disabled"}`}
            style={resetButtonStyles}
            onClick={resetPassword}
          >
            {resetButtonContents}
          </button>
          <div className="log-in-link-container">
            <Link to="/" className="log-in-link">
              Just remembered it? Log In Here
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ResetPassword);
