import React from "react";
// import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useModal } from "frontend-shared/hooks";
// import OnboardingModalBody from "src/components/OnboardingModal";
import { Redirect } from "frontend-shared/components";
import { PuffLoader } from "react-spinners";
import ClientDataStore from "src/stores/ClientDataStore";
import Auth from "src/components/@Auth";
import AuthStore from "src/stores/AuthStore";
import Plans from "src/components/@Plans";
import Pay from "src/components/@Pay";
import ThankYou from "src/components/@ThankYou";
import "./App.scss";

// if (process.env.REACT_APP_STAGE !== "prod") labelFaviconWithStage(process.env.REACT_APP_STAGE);

const Main = observer(() => {
  return (
    <div className="main-content">
      <Routes>
        <Route element={<Plans />} path="/" />
        <Route element={<ThankYou />} path="/thankyou" />
        <Route element={<Pay />} path="/checkout" />
        <Route element={<Redirect to="/" />} path="*" />
      </Routes>
    </div>
  );
});

const App = observer(() => {
  const { authenticated, authDetermined } = AuthStore || {};

  if (!authDetermined || ClientDataStore.loading) {
    return (
      <div className="loader-container">
        <PuffLoader loading className="loader" size={80} />
      </div>
    );
  }

  if (authenticated) return <Main />;

  return <Auth />;
});

function ProvidersWrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default ProvidersWrappedApp;
