import * as ULID from "ulid";
import { parseISO, format } from "date-fns";

const generateULID = dateString => {
  if (dateString) {
    try {
      const seedDate = parseISO(dateString);
      const seedDateEpoch = format(seedDate, "T");
      const ulid = ULID.ulid(Number(seedDateEpoch));
      return ulid;
    } catch (e) {
      console.warn(`Error seeding ULID with date: ${dateString} ${e}`);
      return ULID.ulid();
    }
  }

  return ULID.ulid();
};

export default generateULID;
