import React, { useState } from "react";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
// import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
// import { encodeParamSignature } from "../../../utils";
import AuthStore from "src/stores/AuthStore";
import { useOnEnterPressed } from "frontend-shared/hooks";
import { Input } from "frontend-shared/components";
import "./SignUp.scss";
import FullLogo from "src/components/FullLogo";

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const checkRegisterEnabled = ({ firstName, lastName, email, phone, password, confirmPassword }) => {
  try {
    return firstName && lastName && emailIsValid(email) && password && confirmPassword && password === confirmPassword;
  } catch {
    return false;
  }
};

function SignUp() {
  const [firstName, setFirstName] = useState("Gorb");
  const updateFirstName = ({ target }) => setFirstName(target.value);

  const [lastName, setLastName] = useState("Numbersons");
  const updateLastName = ({ target }) => setLastName(target.value);

  const [email, setEmail] = useState(`tanner+${Math.ceil(Math.random() * 100)}@nascent-labs.com`);
  const updateEmail = ({ target }) => setEmail(target.value);

  const [phone, setPhone] = useState("8017352877");
  const updatePhone = ({ target }) => {
    // if (!isNaN(Number(phone.slice(-1)))) {
    //   const phoneFormatter = new AsYouType("US");
    //   const phone = phoneFormatter.input(target.value);
    //   setPhone(phone);
    // } else {
    setPhone(target.value);
    // }
  };

  const navigate = useNavigate();

  const [password, setPassword] = useState("tanner1221");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [confirmPassword, setConfirmPassword] = useState("tanner1221");
  const updateConfirmPassword = ({ target }) => setConfirmPassword(target.value);

  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [generalError, setGeneralError] = useState(null);

  const signUpEnabled = checkRegisterEnabled({ firstName, lastName, email, phone, password, confirmPassword });

  const signUp = async () => {
    if (signUpEnabled) {
      setEmailError(null);
      setPhoneError(null);
      setGeneralError(null);

      const signUpParams = {
        firstName,
        lastName,
        email,
        password
      };
      const { success, error } = await AuthStore.signUp(signUpParams, navigate);
      if (success) {
        // AuthStore.tempSignupData = { email, password };
        navigate("/verify");
      } else {
        if (error === "User already exists") {
          setEmailError(null);
          setPhoneError(null);
          setGeneralError("An account with the given email already exists.");
        } else if (error === "An account with the given phone number already exists.") {
          setEmailError(null);
          setPhoneError(error);
          setGeneralError(null);
        } else {
          setEmailError(null);
          setPhoneError(null);
          setGeneralError("Sign up error - double check your entries.");
        }
      }
    }
  };
  useOnEnterPressed(signUp);

  const buttonContents = AuthStore.loading ? "LOADING" : "Sign Up";
  const buttonStyles = signUpEnabled
    ? AuthStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };

  return (
    <div className="sign-up-wrapper">
      <div className="auth-contents">
        <div className="auth-square-title">Create Account</div>
        <div className="auth-square">
          <div className="auth-inputs">
            <div className="inputs-row">
              <Input type="text" placeholder="First Name" value={firstName} onChange={updateFirstName} error={generalError ? " " : null} />
              <Input type="text" placeholder="Last Name" value={lastName} onChange={updateLastName} error={generalError ? " " : null} />
            </div>
            <Input
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={updateEmail}
              error={emailError || (generalError ? " " : null)}
            />
            <Input
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={updatePhone}
              error={phoneError || (generalError ? " " : null)}
            />
            <Input type="password" placeholder="Password" value={password} onChange={updatePassword} error={generalError ? " " : null} />
            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={updateConfirmPassword}
              error={generalError}
            />
            <button
              //style={buttonStyles}
              className="auth-button"
              onClick={signUp}
            >
              {buttonContents}
            </button>
          </div>
          <div className="redirect">
            <Link className="redirect" to="/auth">
              <span>Already have an account? </span>
              <span className="auth-link">Sign In</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(SignUp);
