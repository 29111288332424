import React, { useState } from "react";
import { observer } from "mobx-react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useOnEnterPressed } from "frontend-shared/hooks";
import { Input, Redirect } from "frontend-shared/components";
// import { AuthStore } from "src/stores";
// import Verify from "./Verify";
// import Setup from "./Setup";
// import ResetPassword from "./ResetPassword";
// import ResetPasswordSuccess from "./ResetPasswordSuccess";
import "./Auth.scss";
import AuthStore from "src/stores/AuthStore";
import SignUp from "./@SignUp";
import VerifyAccount from "./@VerifyAccount";
import ResetPassword from "./@ResetPassword/ResetPassword";
import ResetPasswordSuccess from "./@ResetPasswordSuccess";
import axios from "axios";
import { request } from "frontend-shared/utils";
// import { recordEvent } from "src/services/AnalyticsService";

const Auth = observer(() => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const attemptAuth = async () => {
    setEmailError(null);
    setPasswordError(null);
    const { success, error } = await AuthStore.signIn(email, password, navigate);
    if (!success) {
      if (error === "Incorrect username or password.") {
        setEmailError(" ");
        setPasswordError(error);
      } else if (error === "User does not exist.") {
        setEmailError(error);
      } else {
        setEmailError(" ");
        setPasswordError("Sign in error - double check your entries.");
      }
    }
  };
  useOnEnterPressed(attemptAuth);

  const buttonContents = AuthStore.loading ? "LOADING" : "SIGN IN";
  const buttonStyles = AuthStore.loading ? { opacity: 0.5, pointerEvents: "none" } : {};

  return (
    <div className="auth-wrapper">
      {/* <div className="left-col">
        <Logo color="var(--secondary-color)" className="auth-logo" />
      </div> */}
      <div className="right-col">
        <div className="auth-content">
          <h3 className="auth-logo">
            <div className="logo">
              Farrier <span className="bud">Plus</span>
            </div>
          </h3>
          <div className="auth-square">
            <div className="auth-inputs">
              <Input type="text" placeholder="Email Address" value={email} onChange={updateEmail} error={emailError} />
              <Input type="password" placeholder="Password" value={password} onChange={updatePassword} error={passwordError} />
              <button style={buttonStyles} className="auth-button" onClick={attemptAuth}>
                {buttonContents}
              </button>
            </div>
            <div className="options-links">
              <Link className="link" to="/resetpassword">
                Forgot your password?
              </Link>
              <Link className="link" to="/signup">
                No Account? Create one!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const AuthRouter = () => {
  return (
    <Routes primary={false}>
      <Route element={<Auth />} path="/auth" />
      <Route element={<SignUp />} path="/signup" />
      <Route element={<VerifyAccount />} path="/verify" />
      <Route element={<ResetPassword />} path="/resetpassword" />
      <Route element={<ResetPasswordSuccess />} path="/resetpassword/success" />
      <Route element={<Redirect to="/auth" />} path="*" />
    </Routes>
  );
};

export default AuthRouter;
