import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Redirect.scss";

export default function Redirect({ to = "/" }) {
  const navigate = useNavigate();
  // eslint-disable-next-line
  useEffect(() => navigate(to), []);
  return <div className="redirect">Redirecting...</div>;
}
