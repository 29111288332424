import { request } from "frontend-shared/utils";
import { makeAutoObservable } from "mobx";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY, WEB_APP_URL } from "src/config";
import AuthStore from "./AuthStore";

class ClientDataStore {
  constructor() {
    makeAutoObservable(this);
    this.initializeStripe();
  }

  stripeLoading = false;
  stripePromise = null;

  loading = false;
  setupIntentSecret = "";

  get stripeOptions() {
    return { clientSecret: this.setupIntentSecret };
  }

  async initializeStripe() {
    try {
      this.stripeLoading = true;
      this.stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
      await this.stripePromise;
      this.stripeLoading = false;
    } catch (err) {
      console.warn(err);
      this.stripeLoading = false;
    }
  }

  async fetchSetupIntentSecret(navigate) {
    this.loading = true;
    const info = await request.get("/v1/stripe/info");
    if (info.redirect) {
      AuthStore.signOut();
      // window.location.replace(WEB_APP_URL);
    }
    this.setupIntentSecret = info.secret;
    navigate("/checkout");
    this.loading = false;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setId(id) {
    this.id = id;
  }

  setAssetURL(assetURL) {
    this.assetURL = assetURL;
  }
}

export default new ClientDataStore();
