import React, { useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { PuffLoader } from "react-spinners";
import { useQueryParams } from "frontend-shared/hooks";
import { WEB_APP_URL } from "src/config";
import { ClientDataStore } from "src/stores";
import "./ThankYou.scss";
import { toJS } from "mobx";
import { request } from "frontend-shared/utils";

const titleMessages = {
  succeeded: "Awesome - you're all set!",
  processing: "Hang tight, your payment is processing.",
  requires_payment_method: "Looks like your payment failed.",
  default: "Looks like something's not right here."
};

const subtitleMessages = {
  succeeded: "We'll log you in automatically in a few seconds. Glad to have you with us!",
  processing: "We'll update you as soon as we get confirmation.",
  requires_payment_method: "We'll send you back to the checkout page in a few seconds if you want to try another payment method.",
  default: "Hold on - we're redirecting you back to the checkout page."
};

const ThankYou = () => {
  const stripe = useStripe();
  const { setup_intent_client_secret: setupIntentClientSecret, plan, coupon } = useQueryParams();
  const navigate = useNavigate();

  const [setupIntentStatus, setSetupIntentStatus] = useState();

  const checkSetupIntentStatus = useCallback(async () => {
    if (stripe) {
      const { setupIntent } = await stripe.retrieveSetupIntent(setupIntentClientSecret);
      const { status } = setupIntent || {};
      setSetupIntentStatus(status);
    }
  }, [stripe, setupIntentClientSecret]);

  useEffect(() => {
    if (!setupIntentStatus) {
      checkSetupIntentStatus();
    } else {
      setTimeout(async () => {
        if (setupIntentStatus === "succeeded") {
          await request.post("/v1/stripe/subscribe", { body: { plan, coupon } });
          setTimeout(() => window.location.replace(WEB_APP_URL), 3000);
        } else if (setupIntentStatus === "processing") checkSetupIntentStatus();
        // if (setupIntentStatus === "succeeded") setTimeout(() => window.location.replace(WEB_APP_URL), 3000);
        else setTimeout(() => navigate("/checkout"), 3000);
      }, 1000);
    }
  }, [setupIntentStatus, checkSetupIntentStatus, navigate]);

  if (!setupIntentStatus) {
    return (
      <div className="thank-you-loading">
        <PuffLoader loading className="loader" size={80} />
      </div>
    );
  }

  return (
    <div className="thank-you-root-container">
      <div className="title-text">{titleMessages?.[setupIntentStatus] || titleMessages?.default}</div>
      <div className="subtitle-text">{subtitleMessages?.[setupIntentStatus] || subtitleMessages?.default}</div>
    </div>
  );
};

const StripeWrappedThankYou = () => {
  const { stripePromise } = ClientDataStore || {};
  const { setup_intent_client_secret: setupIntentClientSecret } = useQueryParams();
  return (
    <Elements stripe={stripePromise} options={{ clientSecret: setupIntentClientSecret }}>
      <ThankYou />
    </Elements>
  );
};

export default observer(StripeWrappedThankYou);
