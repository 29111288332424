import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { AuthStore, ClientDataStore } from "src/stores";
import "./Plans.scss";

const AcceptTerms = () => {
  const navigate = useNavigate();

  const disabled = false;

  const getSetupIntentSecret = async () => {
    await ClientDataStore.fetchSetupIntentSecret(navigate, "");
  };

  useEffect(() => {
    async function fetchData() {
      await getSetupIntentSecret();
    }
    if (!disabled) {
      fetchData();
    }
  });

  const logOut = async () => await AuthStore.signOut();

  return (
    <div className="terms-root-container">
      <div className="content">
        <div className="thankyou" onClick={getSetupIntentSecret}>
          <p className="title">get subscrription</p>
        </div>
        <div className="thankyou" onClick={logOut}>
          <p className="title">larg ooouut</p>
        </div>
      </div>
    </div>
  );
};

export default observer(AcceptTerms);
